import { SuspenseRoute } from 'app/SuspenseRoute';
import { Header } from 'components/header/Header';
import { NavigationControlProvider } from 'components/header/NavigationControllerProvider';
import { Sidebar } from 'components/to-migrate/sidebar/Sidebar';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import { BookmarkView } from 'views/bookmarkview/BookmarkView';
import { HomeView } from 'views/homeview/HomeView';
import { StudentSummary } from 'views/studentview/StudentSummary';
import { TrackingView } from 'views/trackingview/TrackingView';

export const router = createBrowserRouter([
  {
    children: [
      {
        path: '/',
        element: <Navigate to="/teacher" replace={true} />,
      },
      {
        path: '/teacher',
        element: (
          <NavigationControlProvider>
            <Header />
            <Sidebar>
              <SuspenseRoute>
                <Outlet />
              </SuspenseRoute>
            </Sidebar>
          </NavigationControlProvider>
        ),
        children: [
          {
            path: '',
            element: <HomeView />,
          },
          {
            path: 'tracking',
            element: <TrackingView />,
          },
          {
            path: 'student/:studentID',
            element: <Outlet />,
            children: [
              {
                path: 'logs',
                element: <StudentSummary />,
              },
            ],
          },
          {
            path: 'bookmarks',
            element: <BookmarkView />,
          },
        ],
      },
    ],
  },
]);
