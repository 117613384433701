import { UserProductData } from '@sparx/api/apis/sparx/landing/v1/landing';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  getSchoolIdFromSession,
  getSchoolIDFromUrl,
  redirectToLogin,
  userProductDataService,
} from 'api/auth';

interface Session extends UserProductData {
  schoolID: string;
}

const sessionQuery: UseQueryOptions<Session, Error> = {
  queryKey: ['session'],
  queryFn: async () => {
    const schoolID = await getSchoolIdFromSession();

    // Ensure that the school that is in the URL is the one that we currently have a session for.
    // If there is a mismatch then we redirect to the login page for the correct school.
    //
    // We do it here so that the session query never actually resolves, preventing the page from
    // loading and then redirecting straight away.
    const urlSchoolId = getSchoolIDFromUrl();
    if (!schoolID || (urlSchoolId && urlSchoolId !== schoolID)) {
      await redirectToLogin();
    }

    const data = await userProductDataService.getUserProductData({
      schoolName: `schools/${schoolID}`,
    }).response;

    return { ...data, schoolID: schoolID! };
  },
  cacheTime: Infinity,
  staleTime: Infinity,
};

export const useSession = (opts?: { suspense: boolean }) => useQuery({ ...sessionQuery, ...opts });
