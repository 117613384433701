import {
  ISchoolsServiceClient,
  SchoolsServiceClient,
} from '@sparx/api/apis/sparx/school/v2/schools.client';
import { interfaces } from 'inversify';
import { useOptionalInjection } from 'inversify-react';

import { container } from '../di/container';
import { errorTransport } from '../errorTransport';

export const schoolServiceId: interfaces.ServiceIdentifier<ISchoolsServiceClient> =
  Symbol.for('schoolService');

export const setSchoolsClient = (client: ISchoolsServiceClient) => {
  container.bind(schoolServiceId).toConstantValue(client);
};

export const useSchoolsClient = (): ISchoolsServiceClient =>
  useOptionalInjection(schoolServiceId, () => new SchoolsServiceClient(errorTransport));
