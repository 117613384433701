import { UserBookLog } from '@sparx/api/apis/sparx/reading/bookmark/reporting/v1/reporting';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp as pbTimestamp } from '@sparx/api/google/protobuf/timestamp';
import {
  differenceInDays,
  isSaturday,
  isToday,
  nextSaturday,
  previousSaturday,
  setHours,
  startOfDay,
} from 'date-fns';
import { useMemo } from 'react';
import { studentsInGroup } from 'utils/students';

const reportingHour = 0;

export const useReportingPeriod = () =>
  useMemo(() => {
    const now = new Date();
    let start;
    if (isSaturday(now)) {
      start = startOfDay(now);
    } else {
      start = previousSaturday(startOfDay(now));
    }
    start = setHours(start, reportingHour);
    const end = setHours(nextSaturday(start), reportingHour);
    return { start, end };
  }, []);

export const useBookLogStats = (logs?: UserBookLog[]) => {
  const { start, end } = useReportingPeriod();

  return useMemo(() => {
    const today = startOfDay(new Date());

    const studentReadToday: Record<string, boolean> = {};
    const studentDaysRead: Record<string, Set<number>> = {};
    const studentLogs: Record<string, UserBookLog[]> = {};

    for (const log of logs || []) {
      if (!log.timestamp) continue;

      const timestamp = pbTimestamp.toDate(log.timestamp);

      // Only include logs between dates
      if (timestamp < start || timestamp >= end) continue;

      if (isToday(timestamp)) {
        studentReadToday[log.userId] = true;
      }
      const dayTimestamp = startOfDay(timestamp);
      const diff = differenceInDays(today, dayTimestamp);

      if (diff <= 7) {
        if (!studentDaysRead[log.userId]) studentDaysRead[log.userId] = new Set<number>();
        studentDaysRead[log.userId].add(diff);
      }

      studentLogs[log.userId] = [...(studentLogs[log.userId] || []), log];
    }

    return { studentReadToday, studentDaysRead };
  }, [logs, start, end]);
};

export interface GroupStat {
  group: Group;
  readToday: number;
  readTodayPercent: number;
  total: number;
  daysRead: { bad: number; okay: number; good: number };
}

export const useGroupStats = (
  groups?: Group[],
  students?: Student[],
  studentReadToday?: Record<string, boolean>,
  studentDaysRead?: Record<string, Set<number>>,
): GroupStat[] =>
  useMemo(() => {
    return (
      groups?.map(group => {
        const groupStudents = studentsInGroup(students || [], group.name);
        const readToday = groupStudents.filter(s => studentReadToday?.[s.studentId]).length;

        const daysRead = { bad: 0, okay: 0, good: 0 };
        for (const student of groupStudents) {
          const days = studentDaysRead?.[student.studentId]?.size || 0;

          if (days >= 3) daysRead.good++;
          else if (days > 0) daysRead.okay++;
          else daysRead.bad++;
        }

        const readTodayPercent = (readToday / groupStudents.length) * 100;

        return {
          group,
          readToday,
          readTodayPercent,
          total: groupStudents.length,
          daysRead,
        };
      }) || []
    );
  }, [groups, students, studentReadToday, studentDaysRead]);
